<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>الطلبات</h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>رقم الطلب</th>
                <th>العميل</th>
                <th>المنطقة</th>
                <th>تاريخ الطلب</th>
                <th>الجهاز</th>
                <th>الكمية</th>
                <th v-if="user.admin">اجمالي المبلغ</th>
                <th>الحالة</th>
                <th>خيارات</th>
              </thead>
              <tbody>
                <tr v-for="order in orders" :key="order._id">
                  <td>
                    {{ order.order_id }}
                  </td>
                  <td>
                    {{ order.client.name }}
                  </td>
                  <td>
                    {{ order.place.title }}
                    <br />
                    <small v-if="user.admin"> التوصل: {{ order.place.delivery_price }} </small>
                    <br />
                    <small v-if="user.admin"> التركيب: {{ order.place.install_price }} </small>
                  </td>
                  <td>
                    {{ order.date }}
                  </td>
                  <td>
                    {{ order.device.title }}
                  </td>
                  <td>
                    {{ order.count }}
                  </td>
                  <td style="font-family: Arial !important" v-if="user.admin">
                    {{ order.total }} ريال
                  </td>
                  <td>
                    <span
                      class="badge bg-warning text-dark"
                      v-if="order.status == 0"
                      >في انتظار التحقق من الدفع</span
                    >
                    <span class="badge bg-primary" v-if="order.status == 1"
                      >تم التأكد من الدفع وفي انتظار قبول الطلب</span
                    >
                    <span class="badge bg-primary" v-if="order.status == 2"
                      >تم قبول الطلب وفي انتظار التوصيل</span
                    >
                    <span class="badge bg-info" v-if="order.status == 3"
                      >جاري التوصيل</span
                    >
                    <span class="badge bg-success" v-if="order.status == 4"
                      >تم التوصيل</span
                    >
                    <span class="badge bg-success" v-if="order.status == 5"
                      >تم التركيب</span
                    >
                    <span class="badge bg-danger" v-if="order.status == 6"
                      >تم رفض الدفع</span
                    >
                    <span class="badge bg-danger" v-if="order.status == 7"
                      >حدث مشكلة</span
                    >
                    <span class="badge bg-danger" v-if="order.status == 8"
                      >تم الالغاء</span
                    >
                    <div v-if="user.admin">{{ order.admin }}</div>
                  </td>
                  <td>
                    <!-- primary -->
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      text="خيارات"
                      size="sm"
                      right
                      variant="primary"
                    >
                      <b-dropdown-item
                        @click="eorder = order"
                        v-b-modal.modal-1
                      >
                        <i class="fa fa-info"></i>
                        تفاصيل الطلب
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="eorder = order"
                        v-b-modal.modal-2
                      >
                        <i class="fa fa-star"></i>
                        تغيير الحالة
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل الطلب"
      ok-only
      size="lg"
      hide-footer
      ok-title="Accept"
    >
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered">
          <tbody>
            <tr>
              <td>العميل</td>
              <td>
                {{ eorder.client.name }}
                <br />
                {{ eorder.client.phone }}
              </td>
            </tr>
            <tr>
              <td>الجوال</td>
              <td>{{ eorder.phone }}</td>
            </tr>
            <tr>
              <td>العنوان</td>
              <td>{{ eorder.address }}</td>
            </tr>
            <tr>
              <td>ملاحظات العميل</td>
              <td>{{ eorder.notes }}</td>
            </tr>
            <tr>
              <td>ملاحظات المسؤول</td>
              <td>{{ eorder.admin_notes }}</td>
            </tr>
            <tr>
              <td>الجهاز</td>
              <td>{{ eorder.device.title }}</td>
            </tr>
            <tr>
              <td>الكمية</td>
              <td>{{ eorder.count }}</td>
            </tr>
            <tr v-if="user.admin">
              <td>اجمالي المبلغ</td>
              <td>{{ eorder.total }}</td>
            </tr>
            <tr>
              <td>يشمل التركيب</td>
              <td>{{ eorder.install ? "نعم" : "لا" }}</td>
            </tr>
            <tr v-if="user.admin">
              <td>ايصال الدفع</td>
              <td>
                <a :href="eorder.file" target="_blank"
                  ><i class="fa fa-file"></i> عرض الملف</a
                >
              </td>
            </tr>
            <tr>
              <td>السجل</td>
              <td>
                <ol>
                  <li v-for="log in eorder.logs" :key="log.date">
                    <i class="fa fa-calendar" :title="log.date"></i>
                    {{ log.action }}
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title="تغيير حالة الطلب"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <template v-for="status in statuses">
        <div
          class="col-12 g"
          :key="status.title"
          v-if="checkStatusRule(status.title)"
        >
          <button
            @click="
              status_text = status.id;
              changeStatus(eorder._id, status.title);
            "
            :class="
              'text-left btn btn-block ' +
              (status.title == eorder.status
                ? 'btn-relief-primary'
                : 'btn-outline-primary')
            "
          >
            {{ user.admin ? status.title + 1 : "" }} => {{ status.id }}
          </button>
        </div></template
      >
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BDropdown, BDropdownItem, VBModal } from "bootstrap-vue";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  methods: {
    checkStatusRule(id) {
      var g = this;
      if (user.admin) {
        return true;
      } else {
        if ([2, 3, 4, 5, 7].includes(id)) {
          return true;
        } else {
          return false;
        }
      }
    },
    changeStatus(id, status) {
      if (confirm("متأكد من تغيير حالة الطلب؟")) {
        var g = this;
        var notes = prompt(
          "هل لديك اي ملاحظات؟ (ليراها العميل)",
          g.eorder.admin_notes
        );
        $.post(api + "/console/orders/change-status", {
          jwt: user.jwt,
          id: id,
          status: status,
          notes: notes,
          status_text: g.status_text,
        }).then(function (r) {
          if (r.status == 100) {
            g.eorder.status = status;
            g.eorder.logs = r.response;
          }
        });
      }
    },
  },
  data() {
    return {
      orders: [],
      eorder: {
        client: {},
        device: {},
        status_text: "",
        logs: [],
        user: user
      },
      user: user,
      statuses: [
        {
          title: 0,
          id: "في انتظار التحقق من الدفع",
        },
        {
          title: 1,
          id: "تم التأكد من الدفع وفي انتظار قبول الطلب",
        },
        {
          title: 2,
          id: "تم قبول الطلب وفي انتظار التوصيل",
        },
        {
          title: 3,
          id: "جاري التوصيل",
        },
        {
          title: 4,
          id: "تم التوصيل",
        },
        {
          title: 5,
          id: "تم التركيب",
        },
        {
          title: 6,
          id: "تم رفض الدفع",
        },
        {
          title: 7,
          id: "حدث مشكلة",
        },
        {
          title: 8,
          id: "الغاء الطلب",
        },
      ],
    };
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  created() {
    var g = this;
    $.post(api + "/console/orders/list", {
      jwt: user.jwt,
    }).then(function (r) {
      g.orders = r.response;
    });
  },
};
</script>

<style>
</style>